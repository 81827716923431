import styled from '@emotion/styled'
import colors from '../../colors'

interface Props {
  big?: boolean
}

const Button = styled.button<Props>`
  background: ${colors.currentPlayer};
  border: none;
  border-radius: 5px;

  color: #fff;
  padding: ${({ big }) => (big ? '10px 30px' : '7px 20px')};
  font-size: ${({ big }) => (big ? '1.7rem' : '1.4rem')};

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: #444;
  }
`
export default Button
