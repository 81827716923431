import React from 'react'

interface Props {
  x: number
  y: number
}

const PlayerTitle: React.FC<Props> = ({ x, y, children }) => (
  <text
    x={x}
    y={y}
    fill="#eee"
    width={200}
    textAnchor="middle"
    dominantBaseline="baseline"
    style={{
      textShadow: 'black 0px 5px 17px',
      fontSize: '25px'
    }}
  >
    {children}
  </text>
)

export default PlayerTitle
