import store, { actions } from '../../store'
import { stopStream } from '../../utils/stopStream'

let audioStream: MediaStream | undefined
let audioContext: AudioContext | undefined
let gainNode: GainNode | undefined
let sourceNode: MediaStreamAudioSourceNode | undefined
let destinationNode: MediaStreamAudioDestinationNode | undefined
let analyserNode: AnalyserNode | undefined

// Analyser config
const fftSize = 512
const fftBins = new Float32Array(fftSize)

export const micAnalysis = {
  volumeThreshold: -70,
  volumeInterval: 50,
  silencesToMute: 10, // in intervals
  silences: 0, // in intervals
  lastVolume: 0,
  muted: false,
  speaking: false
}

function initAudioContext() {
  if (audioContext) return
  // Setup audio context
  audioContext = new (window.AudioContext ||
    (window as any).webkitAudioContext)()
  analyserNode = audioContext.createAnalyser()
  gainNode = audioContext.createGain()
  destinationNode = audioContext.createMediaStreamDestination()

  analyserNode.connect(gainNode)
  gainNode.connect(destinationNode)

  // Connect AudioStream if it exists
  connectStream()

  // Volume control
  analyserNode.fftSize = fftSize
  analyserNode.smoothingTimeConstant = 0.1
  setInterval(() => {
    if (!audioContext || !gainNode || !analyserNode) return
    micAnalysis.lastVolume = getMaxVolume()

    if (micAnalysis.lastVolume >= micAnalysis.volumeThreshold) {
      micAnalysis.silences = 0
      if (!micAnalysis.speaking) {
        micAnalysis.speaking = true
        updateGain()
      }
    } else {
      if (micAnalysis.speaking) {
        micAnalysis.silences++
        if (micAnalysis.silences === micAnalysis.silencesToMute) {
          micAnalysis.speaking = false
          micAnalysis.silences = 0
          updateGain()
        }
      }
    }
  }, micAnalysis.volumeInterval)
}

// Return values between -Infinity and 0
export function getMaxVolume() {
  if (!analyserNode) return -Infinity
  let maxVolume = -Infinity
  analyserNode.getFloatFrequencyData(fftBins)

  for (let i = 4, ii = fftBins.length; i < ii; i++) {
    if (fftBins[i] > maxVolume && fftBins[i] < 0) {
      maxVolume = fftBins[i]
    }
  }
  return maxVolume
}

export function updateGain() {
  if (!gainNode || !audioContext) return
  gainNode.gain.setValueAtTime(
    micAnalysis.muted || !micAnalysis.speaking ? 0.001 : 1,
    audioContext.currentTime
  )
}

store.subscribe(() => {
  const { micVolumeThreshold } = store.getState().settings
  if (micAnalysis.volumeThreshold !== micVolumeThreshold) {
    micAnalysis.volumeThreshold = micVolumeThreshold
  }
})

// Resume AudioContext on click, see https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
document.addEventListener('click', () => {
  if (audioContext?.state === 'suspended') {
    audioContext?.resume()
  }
})

initAudioContext()

// Mute / unmute
document.addEventListener('keydown', event => {
  if (!audioContext || !gainNode) return
  if (event.key === 'm') {
    micAnalysis.muted = !micAnalysis.muted
    updateGain()
    store.dispatch(actions.player.setAudio(!micAnalysis.muted) as any)
  }
})

export function getMicAudioAnalyser() {
  return analyserNode
}

export function getMicrophoneConstraints(): MediaStreamConstraints {
  const { audioinput } = store.getState().settings
  return {
    audio: {
      channelCount: 1,
      deviceId: { ideal: audioinput },
      autoGainControl: false
    }
  }
}

async function getMediaStream(
  tracks?: MediaStreamTrack[]
): Promise<MediaStream> {
  if (tracks) {
    return new MediaStream(tracks.filter(track => track.kind === 'audio'))
  } else {
    // Get stream from microphone
    return navigator.mediaDevices.getUserMedia(getMicrophoneConstraints())
  }
}

async function connectStream() {
  if (!audioStream) return
  if (sourceNode) sourceNode.disconnect()

  if (audioContext && analyserNode) {
    sourceNode = audioContext.createMediaStreamSource(audioStream)
    sourceNode.connect(analyserNode)
  }

  if (destinationNode) {
    await store.dispatch(
      actions.streams.setAudio(destinationNode.stream) as any
    )
    await store.dispatch(actions.player.setAudio(true) as any)
  }
}

export async function startMicrophone(tracks?: MediaStreamTrack[]) {
  audioStream = await getMediaStream(tracks)

  // Store deviceId
  const track = audioStream.getTracks()[0]
  const id = track && track.getCapabilities && track.getCapabilities().deviceId
  if (id) store.dispatch(actions.settings.setAudioInput(id) as any)

  // Connect to AudioContext
  connectStream()
}

export async function stopMicrophone() {
  if (sourceNode) sourceNode.disconnect()
  if (audioStream) stopStream(audioStream)

  await store.dispatch(actions.player.setAudio(false) as any)
  await store.dispatch(actions.streams.setAudio(undefined) as any)
}
