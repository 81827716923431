import React, { useLayoutEffect, useRef } from 'react'
import settings from '../../settings'

const borderSize = settings.player.avatar.border

interface Props {
  stream?: MediaStream
  mirror?: boolean
  x: number
  y: number
  color: string
  size: number
}

const StreamAvatar: React.FC<Props> = ({
  stream,
  mirror,
  x,
  y,
  size,
  color
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useLayoutEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  return (
    <>
      <defs>
        <filter id="shadow" x="-5%" y="-5%" width="110%" height="115%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>

      <circle
        r={size / 2 + borderSize / 2}
        cx={x}
        cy={y}
        stroke={color}
        strokeWidth={borderSize}
        fill="black"
        filter="url(#shadow)"
      />

      <foreignObject
        x={x - size / 2}
        y={y - size / 2}
        width={size}
        height={size}
      >
        <video
          ref={videoRef}
          width={size}
          height={size}
          autoPlay
          playsInline
          muted
          loop
          style={{
            position: 'fixed', // Safari dirty hack
            borderRadius: size / 2,
            transform: mirror ? 'rotateY(180deg)' : 'none'
          }}
        />
      </foreignObject>
    </>
  )
}

export default StreamAvatar
