import { useMemo } from 'react'
import colors from '../colors'
import { PlayerModes } from '../components/player/Player'

export function useColor(mode: PlayerModes, volume?: number): string {
  return useMemo(() => {
    if (mode === PlayerModes.Current) {
      return colors.currentPlayer
    } else if (mode === PlayerModes.WithinReach && volume) {
      return colors.otherPlayer(volume)
    }
    return '#000'
  }, [mode, volume])
}
