import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../store'
import StreamAvatar from './StreamAvatar'

interface Props {
  faceIndex: number
  x: number
  y: number
  size: number
  color: string
}

const CurrentPlayerVideoStream: React.FC<Props> = ({ faceIndex, ...props }) => {
  const stream = useSelector(
    (state: IRootState) => state.streams.videos[faceIndex]
  )

  // Position avatars
  return (
    <StreamAvatar key={faceIndex} stream={stream} mirror={true} {...props} />
  )
}

export default CurrentPlayerVideoStream
