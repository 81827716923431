import { prepareStore } from 'redux-zap'
import background from '../assets/backgrounds/background1.json'
import { Background } from '../utils/moves'
import { PlayerState } from './player'

interface RoomState {
  readonly id: string
  readonly players: PlayerState[]
  readonly background: Background
}

const initialState: RoomState = {
  id: localStorage.getItem('room') || 'demo',
  players: [],
  background
}

export default prepareStore(initialState, {
  setId(id: string) {
    localStorage.setItem('room', id)
    return { id }
  },

  setPlayers: (list: PlayerState[]) => ({ players: list }),

  setPlayerName: (peerId: string, name: string) => state => ({
    players: state.players.map(player =>
      player.peerId === peerId ? { ...player, name } : player
    )
  }),

  setPlayerAudio: (peerId: string, audio: boolean) => state => ({
    players: state.players.map(player =>
      player.peerId === peerId ? { ...player, audio } : player
    )
  }),

  setPlayerFaces: (peerId: string, faces: number) => state => ({
    players: state.players.map(player =>
      player.peerId === peerId ? { ...player, faces } : player
    )
  }),

  setPlayerPosition: (peerId: string, x: number, y: number) => state => ({
    players: state.players.map(player =>
      player.peerId === peerId ? { ...player, x, y } : player
    )
  })
})
