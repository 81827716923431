import { PeerJSOption } from 'peerjs'

export default {
  supportEmail: 'yapo@skreo.net',
  googleAnalyticsID: 'UA-91354331-4',
  sentryDsn:
    'https://1a3924134edd4ddb827e09fc7ba7e4c2@o297372.ingest.sentry.io/5205185',

  player: {
    moveSpeed: 500, // px/s
    moveStep: 200, // ms
    avatar: {
      size: 150,
      border: 8
    }
  },

  video: {
    bandwidth: 100, // kbps
    retryDelay: 2000,
    fps: 15,
    canvasSize: 300,
    maxFaces: 6,
    // Interval of face detection
    faceDetectionInterval: 1000,
    // Speed of face transition (higher is slower)
    faceTransitionRatio: 50,
    // Time without detected face after which we use default box
    noFaceDefaultDelay: 2000,
    // Distances in px to adapt bandwidth
    distancesHalfBandwidth: [400, 600, 800],
    distanceStop: 1200
  },

  audio: {
    retryDelay: 1000,
    // Distances in px to compute volume
    distanceLoud: 400,
    distanceMuted: 800
  },

  p2p: {
    debug: false,
    peerjs: {
      host: 'api.yapo.io',
      secure: true,
      path: '/myapp'
    } as PeerJSOption
  },

  socketio: {
    uri: process.env.REACT_APP_SOCKET_URL || 'https://api.yapo.io',
    namespace: process.env.REACT_APP_SOCKET_NAMESPACE || 'yapo'
  }
}
