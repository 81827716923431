const sqrt3on6 = Math.sqrt(3) / 6
const sqrt2inverse = 1 / Math.sqrt(2)

export function getNamePosition(facesCount: number, size: number) {
  if (facesCount === 3) {
    return { x: 0, y: -size * (2 * sqrt3on6 + 1 / 2) - 10 }
  } else if (facesCount === 4 || facesCount === 6) {
    return { x: 0, y: -size - 10 }
  } else if (facesCount === 5) {
    return { x: 0, y: -size * (2 * sqrt3on6 + 1 / 2) - 10 }
  }
  return { x: 0, y: -size / 2 - 10 }
}

export function getCirclePosition(
  index: number,
  count: number,
  size: number
): { x: number; y: number } {
  if (count === 2) {
    // Next to each other
    // 0  1
    return {
      x: ((index === 0 ? -1 : 1) * size) / 2,
      y: 0,
    }
  }
  if (count === 3) {
    // Equilateral triangle
    //   1
    // 0   2
    return {
      x: ((index - 1) * size) / 2,
      y: (index === 1 ? 1 : -2) * sqrt3on6 * size,
    }
  }
  if (count === 4) {
    // Square
    // 0  2
    // 1  3
    return {
      x: ((index < 2 ? -1 : 1) * size) / 2,
      y: ((index % 2 === 0 ? -1 : 1) * size) / 2,
    }
  }
  if (count === 5 && index !== 2) {
    // Cross
    // 0   3
    //   2
    // 1   4
    return {
      x: (index > 2 ? 1 : -1) * sqrt2inverse * size,
      y: (index % 3 === 0 ? -1 : 1) * sqrt2inverse * size,
    }
  }
  if (count === 6) {
    // Two rows of three
    // 0  2  4
    // 1  3  5
    return {
      x: (Math.floor(index / 2) - 1) * size,
      y: ((index % 2 === 0 ? -1 : 1) * size) / 2,
    }
  }
  return { x: 0, y: 0 }
}
