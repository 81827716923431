import { useEffect, useState } from 'react'
import { getPeersStats, PeersStats } from '../../network/p2p/stats'

const refreshRate = 1000

function usePeersStats(): PeersStats {
  const [stats, setStats] = useState<PeersStats>({
    videoUp: 0,
    videoDown: 0,
    audioUp: 0,
    audioDown: 0
  })

  useEffect(() => {
    const interval = window.setInterval(
      () => getPeersStats().then(stats => setStats(stats)),
      refreshRate
    )

    return () => window.clearInterval(interval)
  }, [])

  return stats
}

export default usePeersStats
