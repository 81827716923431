import store from '../store'
import {
  getMicrophoneConstraints,
  startMicrophone,
  stopMicrophone
} from './audio/microphone'
import { getWebcamConstraints, startWebcam, stopWebcam } from './video/webcam'

export async function startDevices() {
  // Combine mic and cam constraints
  const constraints = {
    ...getMicrophoneConstraints(),
    ...getWebcamConstraints()
  }

  // Get stream from devices
  const stream = await navigator.mediaDevices.getUserMedia(constraints)

  // Give tracks to mic and cam starters
  await startMicrophone(stream.getAudioTracks())
  await startWebcam(stream.getVideoTracks())
}

export async function stopDevices() {
  await stopMicrophone()
  await stopWebcam()
}

export function areDevicesActive() {
  const { audio, videos } = store.getState().streams
  return audio && videos.length !== 0
}
