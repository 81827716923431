import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { areDevicesActive } from '../../devices'
import { joinRoom } from '../../network/socket'
import { actions } from '../../store'
import Tuto from '../common/Tuto'
import Debug from '../debug/Debug'
import Scene from '../scene/Scene'
import Settings from '../settings/Settings'

const RoomPage: React.FC = () => {
  const { roomId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [tutoDone, setTutoDone] = useState(false)

  useEffect(() => ReactGA.pageview(`/${roomId}`), [roomId])

  useEffect(() => {
    if (!roomId) return

    // Go to home if microphone or camera is disabled
    if (!areDevicesActive()) {
      // Store room id
      dispatch(actions.room.setId(roomId))
      history.push('/')
      return
    }

    // Join room after tutorial
    if (tutoDone) {
      joinRoom(roomId)
    }
  }, [roomId, tutoDone, dispatch, history])

  return !tutoDone ? (
    <Tuto onComplete={() => setTutoDone(true)} />
  ) : (
    <>
      <Scene />
      <Settings />
      <Debug />
    </>
  )
}

export default RoomPage
