import styled from '@emotion/styled'
import React from 'react'

const StyledSvg = styled.svg`
  cursor: pointer;
  rect {
    fill: rgba(255, 255, 255, 0.6);
  }
  &:hover rect {
    fill: rgba(255, 255, 255, 1);
  }
  text {
    font-size: 23px;
    fill: #222;
  }
`

interface Props extends React.SVGProps<SVGSVGElement> {
  label: string
}

const ZoomButton: React.FC<Props> = ({ label, ...props }) => (
  <StyledSvg {...props}>
    <rect width="100%" height="100%" rx="3" />
    <text textAnchor="middle" dominantBaseline="middle" y="50%" x="50%">
      {label}
    </text>
  </StyledSvg>
)

ZoomButton.defaultProps = {
  width: 30,
  height: 30
}

export default ZoomButton
