import { prepareStore } from 'redux-zap'

export interface StreamsState {
  readonly audio?: MediaStream
  readonly videos: (MediaStream | undefined)[]
}

const initialState: StreamsState = {
  videos: []
}

export default prepareStore(initialState, {
  setAudio: (audio?: MediaStream) => ({ audio }),
  setVideos: (videos: (MediaStream | undefined)[]) => ({ videos })
})
