import React from 'react'
import { useSelector } from 'react-redux'
import { getVolumeFromDistance } from '../../devices/audio/volume'
import { IRootState } from '../../store'
import { getObjectsDistance } from '../../utils/getDistance'
import CurrentPlayer from '../player/CurrentPlayer'
import OtherPlayer from '../player/OtherPlayer'
import { PlayerModes } from '../player/Player'

interface Props {
  onCurrentPlayerTouchStart: (clientX: number, clientY: number) => void
}

const Players: React.FC<Props> = ({ onCurrentPlayerTouchStart }) => {
  const currentPlayer = useSelector((state: IRootState) => state.player)
  const players = useSelector((state: IRootState) => state.room.players)

  return (
    <>
      {players.map(player => {
        const current = player.peerId === currentPlayer.peerId
        // Volume 1 for current player is for echo tests
        // Usually there is no audio output stream for current player
        const distance = getObjectsDistance(currentPlayer, player)
        const volume = getVolumeFromDistance(distance)

        return (
          <React.Fragment key={player.peerId}>
            {current ? (
              <CurrentPlayer onTouchStart={onCurrentPlayerTouchStart} />
            ) : (
              <OtherPlayer
                key={player.peerId}
                mode={PlayerModes.WithinReach}
                distance={distance}
                volume={volume}
                player={player}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Players
