import * as Sentry from '@sentry/browser'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import App from './components/App'
import './i18n'
import './index.css'
import './polyfills'
import settings from './settings'

// console.log = (...args: any[]) => alert(args.join(' '))
// console.warn = (...args: any[]) => alert(args.join(' '))
// console.error = (...args: any[]) => alert(args.join(' '))

ReactGA.initialize(settings.googleAnalyticsID)
Sentry.init({ dsn: settings.sentryDsn })

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
