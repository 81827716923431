import React from 'react'

interface SceneProvider {
  getMousePosition: (
    clientX: number,
    clientY: number
  ) => { x: number; y: number }
}

export const SceneContext = React.createContext<SceneProvider>({
  getMousePosition: () => ({ x: 0, y: 0 })
})
export const SceneProvider = SceneContext.Provider
export const SceneConsumer = SceneContext.Consumer
