import styled from '@emotion/styled'

interface Props {
  big?: boolean
}

const Input = styled.input<Props>`
  background-color: #181818;
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: ${({ big }) => (big ? '1.7rem' : '1.1rem')};
  color: #bbb;
  width: 100%;
  &:hover,
  &:focus {
    background-color: black;
  }
  &:invalid {
    box-shadow: none; /* Disable red shadow */
  }
`

export default Input
