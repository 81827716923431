import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { combineStores } from 'redux-zap'
import player from './player'
import room from './room'
import settings from './settings'
import streams from './streams'

export const { reducers, actions, initialState } = combineStores({
  // Prepared stores
  player,
  room,
  streams,
  settings
})

// Obtain and export full type of the root state
export type IRootState = typeof initialState

// Persist with localStorage
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings']
}

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistReducer(persistConfig, combineReducers(reducers)),
  composeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store as any)
export default store
