import styled from '@emotion/styled'
import settings from '../../settings'

interface Props {
  targetX: number
  targetY: number
  size: number
  current: boolean
  moveDistance: number
  maxDistance: number
}

const PlayerContainer = styled.g<Props>`
  position: relative;
  cursor: ${props => (props.current ? 'grab' : 'default')};
  transform: translate(
    ${props => props.targetX}px,
    ${props => props.targetY}px
  );
  will-change: transform;
  transition-property: transform;
  transition-timing-function: linear;
  transition-duration: ${props =>
    props.moveDistance / settings.player.moveSpeed}s;
`

export default PlayerContainer
