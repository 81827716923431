import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { startMicrophone, stopMicrophone } from '../../devices/audio/microphone'
import { changeAudioOutput } from '../../devices/audio/speaker'
import { startWebcam, stopWebcam } from '../../devices/video/webcam'
import { actions, IRootState } from '../../store'
import MicThreshold from './MicThreshold'

enum Steps {
  Loading,
  Error,
  Complete
}

const DevicesSelect: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [step, setStep] = useState(Steps.Loading)

  // Lists of available devices
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([])
  const [audioOutputs, setAudioOutputs] = useState<MediaDeviceInfo[]>([])
  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([])

  // Selected devices
  const audioInputId = useSelector(
    (state: IRootState) => state.settings.audioinput || 'default'
  )
  const audioOutputId = useSelector(
    (state: IRootState) => state.settings.audiooutput || 'default'
  )
  const videoInputId = useSelector(
    (state: IRootState) => state.settings.videoinput || 'default'
  )

  // Get available devices
  useEffect(() => {
    // Create a Promise for error handling
    Promise.resolve()
      // Try to get devices
      .then(() => navigator.mediaDevices.enumerateDevices())
      // Add devices to states
      .then(devices => {
        setAudioInputs(devices.filter(device => device.kind === 'audioinput'))
        setAudioOutputs(devices.filter(device => device.kind === 'audiooutput'))
        setVideoInputs(devices.filter(device => device.kind === 'videoinput'))
        setStep(Steps.Complete)
      })
      .catch(() => setStep(Steps.Error))
  }, [])

  // Audio input change
  const handleAudioInputChange: ChangeEventHandler<HTMLSelectElement> = async event => {
    const deviceId = event.target.value
    dispatch(actions.settings.setAudioInput(deviceId))
    await stopMicrophone()
    await startMicrophone()
  }

  // Audio output change
  const handleAudioOutputChange: ChangeEventHandler<HTMLSelectElement> = async event => {
    const deviceId = event.target.value
    dispatch(actions.settings.setAudioOutput(deviceId))
    changeAudioOutput(deviceId)
  }

  // Video input change
  const handleVideoInputChange: ChangeEventHandler<HTMLSelectElement> = async event => {
    const deviceId = event.target.value
    dispatch(actions.settings.setVideoInput(deviceId))
    await stopWebcam()
    await startWebcam()
  }

  return (
    <div>
      {step === Steps.Loading && t('settings.loading')}
      {step === Steps.Error && t('settings.error')}

      {audioInputs.length !== 0 && (
        <p>
          <span role="img" aria-label="">
            🎤 {t('settings.microphone')}
          </span>
          <select value={audioInputId} onChange={handleAudioInputChange}>
            {audioInputs.map(({ deviceId, label }) => (
              <option key={deviceId} value={deviceId}>
                {label || t('settings.microphone')}
              </option>
            ))}
          </select>
        </p>
      )}

      <p>
        <span role="img" aria-label="">
          🗣️ {t('settings.mic-threshold')}
        </span>
        <MicThreshold />
      </p>

      {audioOutputs.length !== 0 && (
        <p>
          <span role="img" aria-label="">
            🔊 {t('settings.speaker')}
          </span>
          <select value={audioOutputId} onChange={handleAudioOutputChange}>
            {audioOutputs.map(({ deviceId, label }) => (
              <option key={deviceId} value={deviceId}>
                {label || t('settings.speaker')}
              </option>
            ))}
          </select>
        </p>
      )}

      {videoInputs.length !== 0 && (
        <p>
          <span role="img" aria-label="">
            📷 {t('settings.camera')}
          </span>
          <select value={videoInputId} onChange={handleVideoInputChange}>
            {videoInputs.map(({ deviceId, label }) => (
              <option key={deviceId} value={deviceId}>
                {label || t('settings.camera')}
              </option>
            ))}
          </select>
        </p>
      )}
    </div>
  )
}

export default DevicesSelect
