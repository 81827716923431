import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { IRootState } from '../../store'

const StyledSvg = styled.svg`
  text {
    font-size: 25px;
    fill: #eee;
  }
`

const Title: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const roomId = useSelector((state: IRootState) => state.room.id)

  return (
    <StyledSvg {...props}>
      <defs>
        <filter id="title-shadow" x="-5%" y="-5%" width="110%" height="115%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <text
        x="10"
        y="10"
        dominantBaseline="hanging"
        filter="url(#title-shadow)"
      >
        {roomId}
      </text>
    </StyledSvg>
  )
}

export default Title
