import { useEffect, useMemo, useRef } from 'react'
import { addSpeakerSource } from '../devices/audio/speaker'

export function useAudioStream(
  stream: MediaStream | undefined,
  volume: number
) {
  const gainNodeRef = useRef<GainNode>()
  const volumeRef = useRef<number>(volume)

  // Prepare <audio> tag
  const audio = useMemo(() => {
    const audio = document.createElement('audio')
    audio.autoplay = true
    audio.muted = true
    return audio
  }, [])

  // Change volume
  useEffect(() => {
    volumeRef.current = volume
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = Math.max(0.0001, volume)
    }
  }, [volume])

  useEffect(() => {
    if (stream) {
      // Assign stream to <audio>
      // Necessary for some browsers in order to make AudoiContext work
      audio.srcObject = stream

      // Add nodes to AudioContext
      const gainNode = addSpeakerSource(stream)
      gainNodeRef.current = gainNode
      if (gainNode) {
        gainNode.gain.value = Math.max(0.0001, volumeRef.current)
      }
    }

    return () => {
      if (gainNodeRef.current) {
        gainNodeRef.current.disconnect()
        gainNodeRef.current = undefined
      }
    }
  }, [audio, stream])
}
