import styled from '@emotion/styled'

interface Props {
  width?: number
  error?: boolean
}

const Popup = styled.div<Props>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${props => props.width}px;
  max-width: 100%;
  background-color: #282828;
  box-shadow: black 0 0 25px;
  border-radius: 5px;
  padding: 1em;
  font-size: 1.2rem;
  color: ${props => (props.error ? '#ffb38f' : 'inherit')};
  text-align: center;

  button {
    margin-top: 1em;
  }
`

Popup.defaultProps = {
  width: 550
}

export default Popup
