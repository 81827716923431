export function getDistance(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number {
  const a = x1 - x2
  const b = y1 - y2
  return Math.sqrt(a * a + b * b)
}

interface ObjectPosition {
  x: number
  y: number
}

export function getObjectsDistance(
  obj1: ObjectPosition,
  obj2: ObjectPosition
): number {
  return getDistance(obj1.x, obj1.y, obj2.x, obj2.y)
}
