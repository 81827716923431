import styled from '@emotion/styled'
import React from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import settings from '../../settings'
import { actions, IRootState } from '../../store'
import Button from './Button'
import Input from './Input'

interface FormValues {
  roomId: string
  name: string
}

const StartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Form = styled.form`
  background-color: #282828;
  border-radius: 5px;
  padding: 20px;
  width: 240px;
  text-align: center;
  margin-bottom: 10px;
`

const Footer = styled.div`
  color: #d19d49;
  padding: 5px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 20px;
  text-align: left;
  div {
    margin-bottom: 10px;
    margin-left: 12px;
  }
`

interface Props {
  disabled?: boolean
}

const StartForm: React.FC<Props> = ({ disabled }) => {
  const storeRoomId = useSelector((state: IRootState) => state.room.id)
  const storeName = useSelector((state: IRootState) => state.player.name)
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      roomId: storeRoomId,
      name: storeName
    }
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const onSubmit = (values: FormValues) => {
    const name = values.name.trim()
    const roomId = values.roomId.trim().toLowerCase()

    // Set name
    dispatch(actions.player.setName(name))

    // Go to room page
    history.push(`/${roomId}`)
  }

  return (
    <StartContainer>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Label>
          <div>{t('join.room')}</div>
          <Input
            ref={register}
            type="text"
            name="roomId"
            placeholder={t('join.room-placeholder')}
            maxLength={30}
            spellCheck={false}
            required
          />
        </Label>
        <Label>
          <div>{t('join.name')}</div>
          <Input
            ref={register}
            type="text"
            name="name"
            placeholder={t('join.name-placeholder')}
            maxLength={30}
            spellCheck={false}
            required
            autoFocus
          />
        </Label>
        <Button big disabled={disabled}>
          {t('join.submit')}
        </Button>
      </Form>
      <Footer>
        <Trans
          i18nKey="join.footer"
          components={[
            <a href={`mailto:${settings.supportEmail}`} key={0}>
              report bugs
            </a>
          ]}
        />
      </Footer>
    </StartContainer>
  )
}

export default StartForm
