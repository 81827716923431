import styled from '@emotion/styled'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import svgPanZoom from 'svg-pan-zoom'
import { ReactComponent as Background } from '../../assets/backgrounds/background1.svg'
import useWindowSize from '../../hooks/useWindowSize'
import settings from '../../settings'
import store, { actions, IRootState } from '../../store'
import { getDistance } from '../../utils/getDistance'
import Players from './Players'
import { SceneProvider } from './SceneContext'
import Title from './Title'
import ZoomButton from './ZoomButton'

const panZoomOptions: SvgPanZoom.Options = {
  viewportSelector: '.panzoom-group',
  minZoom: 0.3,
  maxZoom: 2,
  zoomScaleSensitivity: 0.5,
  preventMouseEventsDefault: true,
  dblClickZoomEnabled: false
}
const initialZoom = 0.5

const moveStepDuration =
  (settings.player.moveStep / settings.player.moveSpeed) * 1000

interface PressedKeys {
  up: boolean
  down: boolean
  left: boolean
  right: boolean
}

const defaultPressedKeys = {
  up: false,
  down: false,
  left: false,
  right: false
}

const SceneSvg = styled.svg`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

SceneSvg.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg'
}

const Scene: React.FC = () => {
  const svgRef = useRef<SVGSVGElement>(null)
  const groupRef = useRef<SVGGElement>(null)
  const backgroundRef = useRef<SVGSVGElement>(null)
  const panzoomRef = useRef<SvgPanZoom.Instance>()
  const windowSize = useWindowSize()
  const dispatch = useDispatch()

  // Move current player
  const clickShiftRef = useRef<{ x: number; y: number }>()
  const moveTargetRef = useRef<{ x: number; y: number }>()
  const moveIntervalRef = useRef<number>()
  const pressedKeysRef = useRef<PressedKeys>(defaultPressedKeys)

  const background = useSelector((state: IRootState) => state.room.background)
  const backgroundSize = useMemo(
    () => ({
      width: background.width * background.scale,
      height: background.height * background.scale
    }),
    [background]
  )

  // Get mouse position with panzoom transform maatrix
  const sceneContextValue = useMemo(
    () => ({
      getMousePosition: (
        clientX: number,
        clientY: number
      ): { x: number; y: number } => {
        const matrix = groupRef.current!.getScreenCTM()?.inverse()
        const point = (svgRef.current as any).createSVGPoint()
        point.x = clientX
        point.y = clientY
        const { x, y } = point.matrixTransform(matrix)
        return { x, y }
      }
    }),
    []
  )

  const centerOnPlayer = (onlyIfOnBorder?: boolean) => {
    const { x, y } = store.getState().player
    const panzoom = panzoomRef.current
    if (panzoom) {
      const zoom = panzoom.getZoom()

      if (onlyIfOnBorder) {
        const pan = panzoom.getPan()
        const playerX = x * zoom + pan.x
        const playerY = y * zoom + pan.y
        if (
          playerX > window.innerWidth / 6 &&
          playerX < (window.innerWidth * 5) / 6 &&
          playerY > window.innerHeight / 6 &&
          playerY < (window.innerHeight * 5) / 6
        ) {
          return
        }
      }

      panzoom.pan({
        x: -x * zoom + window.innerWidth / 2,
        y: -y * zoom + window.innerHeight / 2
      })
    }
  }

  const moveFollowingMouse = useCallback(() => {
    let moveTarget = moveTargetRef.current
    const { up, down, left, right } = pressedKeysRef.current
    if (!(moveTarget || up || down || left || right)) {
      return
    }

    const { x, y } = store.getState().player

    // Move with arrow
    if (!moveTarget) {
      if (up && left) {
        moveTarget = { x: x - 200, y: y - 200 }
      } else if (up && right) {
        moveTarget = { x: x + 200, y: y - 200 }
      } else if (down && left) {
        moveTarget = { x: x - 200, y: y + 200 }
      } else if (down && right) {
        moveTarget = { x: x + 200, y: y + 200 }
      } else if (up) {
        moveTarget = { x, y: y - 200 }
      } else if (down) {
        moveTarget = { x, y: y + 200 }
      } else if (left) {
        moveTarget = { x: x - 200, y }
      } else if (right) {
        moveTarget = { x: x + 200, y }
      } else {
        moveTarget = { x, y }
      }
    }

    // Compute target point
    const targetPoint = { ...moveTarget }
    const clickShift = clickShiftRef.current
    if (clickShift) {
      targetPoint.x -= clickShift.x
      targetPoint.y -= clickShift.y
    }

    // Compute distance from current position
    const moveVector = [targetPoint.x - x, targetPoint.y - y]
    const distance = getDistance(x, y, targetPoint.x, targetPoint.y)

    // Move by step
    if (distance > settings.player.moveStep) {
      moveVector[0] *= settings.player.moveStep / distance
      moveVector[1] *= settings.player.moveStep / distance
    }
    const newPosition = { x: x + moveVector[0], y: y + moveVector[1] }

    // Apply position and send it to other players
    dispatch(actions.player.setPosition(newPosition.x, newPosition.y))

    // Pan
    centerOnPlayer(true)
  }, [dispatch])

  useEffect(() => {
    // Setup PanZoom
    const svg = svgRef.current
    const options: SvgPanZoom.Options = {
      ...panZoomOptions,
      eventsListenerElement: backgroundRef.current as SVGElement | undefined
    }
    panzoomRef.current = (svg && svgPanZoom(svg, options)) || undefined

    // Initial zoom
    panzoomRef.current?.zoom(initialZoom)

    // Assign random position to player
    const [topLeft, bottomRight] = background.spawnArea
    const { scale } = background
    dispatch(
      actions.player.setPosition(
        Math.random() * (bottomRight[0] - topLeft[0]) * scale +
          topLeft[0] * scale,
        Math.random() * (bottomRight[1] - topLeft[1]) * scale +
          topLeft[1] * scale,
        true
      )
    )

    // And zoom
    setTimeout(() => centerOnPlayer(), 500)

    moveIntervalRef.current = window.setInterval(
      moveFollowingMouse,
      moveStepDuration
    )

    const handleKeydown = (event: KeyboardEvent) => {
      const pressedKeys = pressedKeysRef.current
      let changeMovement = false
      if (event.keyCode === 37 || event.key === 'a') {
        if (!pressedKeys.left) {
          pressedKeys.left = true
          changeMovement = true
        }
      } else if (event.keyCode === 38 || event.key === 'w') {
        if (!pressedKeys.up) {
          pressedKeys.up = true
          changeMovement = true
        }
      } else if (event.keyCode === 39 || event.key === 'd') {
        if (!pressedKeys.right) {
          pressedKeys.right = true
          changeMovement = true
        }
      } else if (event.keyCode === 40 || event.key === 's') {
        if (!pressedKeys.down) {
          pressedKeys.down = true
          changeMovement = true
        }
      }

      if (changeMovement) {
        clickShiftRef.current = undefined
        moveFollowingMouse()
        window.clearInterval(moveIntervalRef.current)
        moveIntervalRef.current = window.setInterval(
          moveFollowingMouse,
          moveStepDuration
        )
      }
    }
    const handleKeyup = (event: KeyboardEvent) => {
      const pressedKeys = pressedKeysRef.current
      if (event.keyCode === 37 || event.key === 'a') {
        pressedKeys.left = false
      } else if (event.keyCode === 38 || event.key === 'w') {
        pressedKeys.up = false
      } else if (event.keyCode === 39 || event.key === 'd') {
        pressedKeys.right = false
      } else if (event.keyCode === 40 || event.key === 's') {
        pressedKeys.down = false
      }
    }

    document.addEventListener('keydown', handleKeydown)
    document.addEventListener('keyup', handleKeyup)

    // Unmount PanZoom at unmount
    return () => {
      panzoomRef.current?.destroy()
      document.removeEventListener('keydown', handleKeydown)
      document.removeEventListener('keyup', handleKeyup)
      window.clearInterval(moveIntervalRef.current)
    }
  }, [backgroundSize, background, dispatch, moveFollowingMouse])

  const handleZoom = useCallback(
    (multiplier: number) => () => {
      panzoomRef.current?.zoomBy(multiplier)
    },
    []
  )

  const handleCurrentPlayerTouchStart = useCallback(
    (clientX: number, clientY: number) => {
      const position = sceneContextValue.getMousePosition(clientX, clientY)
      const { x, y } = store.getState().player
      moveTargetRef.current = position
      clickShiftRef.current = {
        x: position.x - x,
        y: position.y - y
      }
    },
    [sceneContextValue]
  )

  const handleMouseMove = useCallback<React.MouseEventHandler<Element>>(
    event => {
      if (moveTargetRef.current) {
        moveTargetRef.current = sceneContextValue.getMousePosition(
          event.clientX,
          event.clientY
        )
      }
    },
    [sceneContextValue]
  )

  const handleTouchMove = useCallback<React.TouchEventHandler<Element>>(
    event => {
      if (event.touches.length === 1 && moveTargetRef.current) {
        const touch = event.touches[0]
        moveTargetRef.current = sceneContextValue.getMousePosition(
          touch.clientX,
          touch.clientY
        )
      }
    },
    [sceneContextValue]
  )

  const handleMouseUp = useCallback<React.MouseEventHandler<Element>>(() => {
    moveTargetRef.current = undefined
  }, [])

  const handleTouchEnd = useCallback<React.TouchEventHandler<Element>>(() => {
    moveTargetRef.current = undefined
  }, [])

  return (
    <SceneProvider value={sceneContextValue}>
      <SceneSvg
        ref={svgRef}
        width={windowSize.width}
        height={windowSize.height}
        viewBox={`0 0 ${windowSize.width} ${windowSize.height}`}
      >
        <g
          className="panzoom-group"
          ref={groupRef}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Background
            ref={backgroundRef}
            x="0"
            y="0"
            width={backgroundSize.width}
            height={backgroundSize.height}
            style={{ cursor: 'move' }}
          />
          <Players onCurrentPlayerTouchStart={handleCurrentPlayerTouchStart} />
        </g>

        <Title />

        <ZoomButton
          label="+"
          x={5}
          y={windowSize.height - (5 + 30) * 2}
          onClick={handleZoom(1.4)}
        />
        <ZoomButton
          label="-"
          x={5}
          y={windowSize.height - 5 - 30}
          onClick={handleZoom(0.4)}
        />
      </SceneSvg>
    </SceneProvider>
  )
}
export default Scene
