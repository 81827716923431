import React from 'react'
import { useColor } from '../../hooks/useColor'
import { useMove } from '../../hooks/useMove'
import settings from '../../settings'
import { PlayerState } from '../../store/player'
import CurrentPlayerVideoStream from './CurrentPlayerVideoStream'
import PlayerAudioStream from './PlayerAudioStream'
import PlayerContainer from './PlayerContainer'
import PlayerTitle from './PlayerTitle'
import PlayerVideoStream from './PlayerVideoStream'
import { getCirclePosition, getNamePosition } from './positions'

const { size, border } = settings.player.avatar
const sizeWithBorders = size + border * 2

export enum PlayerModes {
  Current,
  WithinReach,
  OutOfReach
}

export interface PlayerProps {
  player: PlayerState
  mode: PlayerModes
  x?: number
  y?: number
  distance?: number
  volume?: number
}

const Player: React.FC<PlayerProps> = ({
  player,
  mode,
  x,
  y,
  distance,
  volume
}) => {
  const move = useMove(x || player.x, y || player.y)
  const color = useColor(mode, volume)
  const streamsCount = Math.max(1, player.faces)
  const namePosition = getNamePosition(streamsCount, sizeWithBorders)

  return (
    <PlayerContainer
      targetX={move.x}
      targetY={move.y}
      size={size}
      current={mode === PlayerModes.Current}
      moveDistance={move.distance}
      maxDistance={settings.audio.distanceMuted}
    >
      {mode !== PlayerModes.Current && (
        <>
          <PlayerTitle x={namePosition.x} y={namePosition.y}>
            {player.name}
          </PlayerTitle>
          {volume ? (
            <PlayerAudioStream
              peerId={player.peerId}
              volume={player.audio ? volume : 0}
            />
          ) : null}
        </>
      )}

      {Array.from({ length: streamsCount }, (x, i) => i).map(faceIndex => {
        // Position avatars
        const position = getCirclePosition(
          faceIndex,
          streamsCount,
          sizeWithBorders
        )

        return mode === PlayerModes.Current ? (
          <CurrentPlayerVideoStream
            key={faceIndex}
            faceIndex={faceIndex}
            size={size}
            color={color}
            x={position.x}
            y={position.y}
          />
        ) : (
          <PlayerVideoStream
            key={faceIndex}
            peerId={player.peerId}
            faceIndex={faceIndex}
            distance={distance || 0}
            size={size}
            color={color}
            x={position.x}
            y={position.y}
          />
        )
      })}
    </PlayerContainer>
  )
}

export default Player
