import styled from '@emotion/styled'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import colors from '../../colors'
import { micAnalysis } from '../../devices/audio/microphone'
import { actions, IRootState } from '../../store'

const volumeColor = colors.currentPlayer

interface MicRangeProps {
  volume: number
}

const MicRange = styled.input<MicRangeProps>`
  -webkit-appearance: none;
  padding: 0;
  margin: 10px 0;
  background: transparent !important;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: linear-gradient(
      to right,
      ${volumeColor},
      ${volumeColor} ${props => props.volume}%,
      #000 ${props => props.volume + 2}%,
      #000 100%
    );
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  &::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 12px;
    border-radius: 3px;
    background: #999;
    cursor: grab;
    -webkit-appearance: none;
    margin-top: -8px;
  }
  &::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: linear-gradient(
      to right,
      ${volumeColor},
      ${volumeColor} ${props => props.volume}%,
      #000 ${props => props.volume + 2}%,
      #000 100%
    );
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  &::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 12px;
    border-radius: 3px;
    background: #999;
    cursor: grab;
  }
  &::-ms-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 12px 0;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: linear-gradient(
      to right,
      ${volumeColor},
      ${volumeColor} ${props => props.volume}%,
      #000 ${props => props.volume + 2}%,
      #000 100%
    );
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &::-ms-fill-upper {
    background: linear-gradient(
      to right,
      ${volumeColor},
      ${volumeColor} ${props => props.volume}%,
      #000 ${props => props.volume + 2}%,
      #000 100%
    );
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  &::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    height: 20px;
    width: 12px;
    border-radius: 3px;
    background: #ffffff;
    cursor: grab;
  }
`

MicRange.defaultProps = {
  type: 'range',
  step: 'any'
}

const MicThreshold: React.FC = () => {
  const dispatch = useDispatch()
  const micThreshold = useSelector(
    (state: IRootState) => state.settings.micVolumeThreshold
  )
  const [volume, setVolume] = useState(0) // 0-100

  // Update volume
  useEffect(() => {
    const interval = window.setInterval(
      () => setVolume(Math.max(0, micAnalysis.lastVolume + 100)),
      100
    )
    return () => window.clearInterval(interval)
  }, [])

  // Value change
  const handleMicThresholdChange: ChangeEventHandler<HTMLInputElement> = async event => {
    const threshold = parseFloat(event.target.value)
    dispatch(actions.settings.setMicThreshold(threshold))
  }

  return (
    <MicRange
      type="range"
      min="-100"
      max="0"
      value={micThreshold}
      onChange={handleMicThresholdChange}
      volume={volume}
    />
  )
}

export default MicThreshold
