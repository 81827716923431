import { prepareStore } from 'redux-zap'
import store from '.'
import { io } from '../network/socket'
import settings from '../settings'
import { generateId } from '../utils/generateId'
import { avoidOtherPlayers, avoidWalls } from '../utils/moves'

export interface PlayerState {
  readonly peerId: string
  readonly name: string
  readonly audio: boolean
  readonly faces: number
  readonly x: number
  readonly y: number
}

const initialState: PlayerState = {
  peerId: generateId(),
  name: localStorage.getItem('name') || '',
  audio: false,
  faces: 0,
  x: 0,
  y: 0
}

export default prepareStore(initialState, {
  setName(name: string) {
    io.emit('name', { name })
    localStorage.setItem('name', name)
    return { name }
  },

  setAudio(audio: boolean) {
    io.emit('audio', { audio })
    return { audio }
  },

  setFaces(faces: number) {
    io.emit('faces', { faces })
    return { faces }
  },

  setPosition(x: number, y: number, force?: boolean) {
    if (!force) {
      const { players, background } = store.getState().room

      // Avoid colliding with walls
      const scale = background.scale
      const minWallDistance =
        (settings.player.avatar.size / 2 + settings.player.avatar.border) /
        scale
      const startPointScaled = [this.x / scale, this.y / scale]
      const endPointScaled = [x / scale, y / scale]
      const endPointFixed = avoidWalls(
        startPointScaled,
        endPointScaled,
        background.walls,
        minWallDistance
      )
      const endPoint = endPointFixed.map(p => p * scale)

      // Avoid colliding with other players
      const minDistance =
        settings.player.avatar.size + settings.player.avatar.border * 2
      ;[x, y] = avoidOtherPlayers(endPoint, this.peerId, players, minDistance)
    }

    io.emit('move', { x, y })
    return { x, y }
  }
})
