import styled from '@emotion/styled'
import React, { useState } from 'react'
import DebugData from './DebugData'

const Panel = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(50, 50, 50, 0.9);
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 0.9rem;

  &:hover {
    background: rgba(70, 70, 70, 0.9);
  }

  h1 {
    font-size: 1.1em;
    font-weight: normal;
    margin: 0;
  }
  &.open h1 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3px 0 0 0;
    span:first-of-type {
      padding-right: 10px;
    }
  }
`

const Debug: React.FC = () => {
  const [show, setShow] = useState(false)

  return (
    <Panel onClick={() => setShow(!show)} className={show ? 'open' : 'closed'}>
      <h1>Debug</h1>
      {show && <DebugData />}
    </Panel>
  )
}

export default Debug
