import React, { useLayoutEffect, useRef } from 'react'

const width = 50
const height = 25

interface Props {
  analyser?: AnalyserNode
}

const AudioFreq: React.FC<Props> = ({ analyser }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useLayoutEffect(() => {
    const canvasCtx = canvasRef.current?.getContext('2d')
    if (!canvasCtx || !analyser) return

    let frameRequest = 0
    analyser.fftSize = 2048
    const bufferSize = analyser.frequencyBinCount // Half of FFT value (Fast Fourier Transform)
    const dataArray = new Uint8Array(bufferSize)

    canvasCtx.clearRect(0, 0, width, height)
    canvasCtx.lineWidth = 2
    canvasCtx.strokeStyle = 'rgb(0, 150, 0)'

    function draw() {
      if (!canvasCtx || !analyser) return

      analyser.getByteTimeDomainData(dataArray)

      canvasCtx.clearRect(0, 0, width, height)
      canvasCtx.beginPath()

      const sliceWidth = (width * 1.0) / bufferSize
      let x = 0

      for (let i = 0; i < bufferSize; i++) {
        const v = dataArray[i] / 128.0
        const y = (v * height) / 2

        if (i === 0) {
          canvasCtx.moveTo(x, y)
        } else {
          canvasCtx.lineTo(x, y)
        }

        x += sliceWidth
      }

      canvasCtx.lineTo(width, height / 2)
      canvasCtx.stroke()

      frameRequest = requestAnimationFrame(draw)
    }

    draw()

    return () => {
      cancelAnimationFrame(frameRequest)
    }
  }, [analyser])

  return <canvas ref={canvasRef} width={width} height={height} />
}

export default AudioFreq
