import React from 'react'
import { useAudioCall } from '../../hooks/useAudioCall'
import { useAudioStream } from '../../hooks/useAudioStream'

interface Props {
  peerId: string
  volume: number
}

const PlayerAudioStream: React.FC<Props> = ({ peerId, volume }) => {
  const stream = useAudioCall(peerId)
  useAudioStream(stream, volume)
  return null
}

export default PlayerAudioStream
